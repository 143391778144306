@charset "utf-8";

/*
Here is how font sizing works.

Setting a font-size in pixels on the base html element is a common way
 of laying the foundation for responsive typography.

The type-size-x variables are just em based sizes that act as 
multipliers of the base font sizes set in pixels.
*/

@import "minimal-mistakes/skins/air"; // skin

/* system typefaces */
$serif: Lora, Georgia, Times, serif !default;
$sans-serif: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI",
  "Helvetica Neue", "Lucida Grande", Arial, sans-serif !default;
$monospace: Monaco, Consolas, "Lucida Console", monospace !default;

/* sans serif typefaces */
$sans-serif-narrow: $sans-serif !default;
$helvetica: Helvetica, "Helvetica Neue", Arial, sans-serif !default;

/* serif typefaces */
$georgia: Georgia, serif !default;
$times: Times, serif !default;
$bodoni: "Bodoni MT", serif !default;
$calisto: "Calisto MT", serif !default;
$garamond: Garamond, serif !default;

$global-font-family           : $serif !default;
$header-font-family           : $sans-serif !default;
$caption-font-family          : $serif !default;

/* type scale */
$type-size-1: 2.441em !default;
$type-size-2: 1.953em !default;
$type-size-3: 1.563em !default;
$type-size-4: 1.25em !default;
$type-size-5: 1.1em !default;
$type-size-6: 0.8em !default;
$type-size-7: 0.8em !default;
$type-size-8: 0.625em !default;

/* headline scale */
$h-size-1: 1.563em !default;
$h-size-2: 1.25em !default;
$h-size-3: 1.125em !default;
$h-size-4: 1.0625em !default;
$h-size-5: 1.03125em !default;
$h-size-6: 1em !default;

@import "minimal-mistakes"; // main partials

html {
  font-size: 14px; // change to whatever

  @include breakpoint($medium) {
    font-size: 16px; // change to whatever
  }

  @include breakpoint($large) {
    font-size: 18px; // change to whatever
  }

  @include breakpoint($x-large) {
    font-size: 20px; // change to whatever
  }
}